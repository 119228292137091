import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "../../Assets/css/contactus.css";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { base_url } from "../../Store/constant";
import { Helmet } from "react-helmet";
const Disclaimer = () => {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("Billing Inquiry");
  const [msg, setMsg] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      subject,
      message: msg,
    };
    axios
      .post(base_url + "/postContactus", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        if (response.status == 200) {
          swal(response.data.msg, "", "success");
          setEmail("");
          setName("");
          setSubject("Billing Inquiry");
          setMsg("");
        } else {
          swal(response.data.msg, "", "error");
        }
      })
      .catch((error) => {
        swal(error.response.data.msg, "", "error");
      });
  };

  return (
    <>
      <Helmet>
        <title>Contact Midjourney Free | AI-Powered Image Generation Tool</title>
        <meta name="description" content="Get in touch with Midjourney Free for inquiries, feedback, or support. Our team is
here to assist you with questions regarding our AI-powered image generation tool."></meta>
<meta name="keywords"
    content="midjourney free, midjourney, ai image generator, midjourney ai, ai photo generator, ai generated images, free ai image generator, ai image generator free, mid journey, generate ai images, midjourney ai free, midjourneyfree, Midjourney free contact"></meta>
         <link rel="alternate" to="https://www.journeyfree.ai/contact-us/about-us/" hreflang="en-us" />
        <link rel="canonical" to= "https://www.journeyfree.ai/contact-us"/>
      </Helmet>
      <div className="contact-us pb-3">
        <Container className="pt-5 mt-3 mb-5">
          <Row className="justify-content-center">
            <Col sm={12}>
              <div className="text-center">
                <h1>Disclaimer for <span className='mid-hide'>mid</span>Journeyfree.ai</h1>
              

                {/* <p>
            Email:{" "}
            <Link to="mailto:support@journeyfree.ai" className="text-dark">
              <b>support@journeyfree.ai</b>
            </Link>
          </p> */}
              </div>
            </Col>
            <Col sm={8}>
              <div className="cont-right ">
                <Form onSubmit={handleFormSubmit} className="mt-4">
                <Row className="justify-content-center">
                      
                       

                       
                        <Col lg={12} className="text-center mt-2">
                        <div className="disclaimer-container" style={{ padding: '20px', lineHeight: '1.6' }}>
     
      <p>
        <strong>Last Updated:</strong> 25 June, 2024
      </p>

      <p>
        Welcome to <span className='mid-hide'> Mid</span>journeyfree.ai! Please read this disclaimer carefully before using our website.
      </p>

      <h2 className="dis_head">1. Independent Platform</h2>
      <p>
        <span className='mid-hide'> Mid</span>journeyfree.ai is an independent platform providing free tools, resources, and services related to AI and generative models. We are <strong>not affiliated with, associated with, or endorsed by <span className='mid-hide'> Mid</span>journey.com</strong>. Any references to <span className='mid-hide'> Mid</span>journey or similar terms are purely descriptive and not meant to imply any connection to <span className='mid-hide'> Mid</span>journey.com or its services.
      </p>

      <h2 className="dis_head">2. Trademarks and Brand Names</h2>
      <p>
        All trademarks, logos, and brand names mentioned on this website belong to their respective owners. Any reference to third-party trademarks is purely for informational purposes and does not imply any endorsement or affiliation.
      </p>

      <h2 className="dis_head">3. No Misrepresentation</h2>
      <p>
        We do not claim to be Midjourney.com or to offer their services. Our platform provides unique resources and tools designed for a different audience. Any similarity in names is coincidental and descriptive of the AI journey or free resources offered here.
      </p>

      <h2 className="dis_head">4. Accuracy and Liability</h2>
      <p>
        While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind about the completeness, accuracy, reliability, or availability of the website or its content.
      </p>

      <h2 className="dis_head">5. External Links</h2>
      <p>
        Our website may contain links to third-party websites. These links are provided for convenience only, and we do not endorse or take responsibility for the content or practices of any third-party sites.
      </p>

      <h2 className="dis_head">6. Contact Information</h2>
      <p>
        For any questions about this disclaimer, please contact us at <a href="mailto:support@journeyfree.ai">Support@journeyfree.ai</a>.
      </p>
    </div>
                        </Col>
                      </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Disclaimer;
